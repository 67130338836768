.App {
  text-align: center;
  background-color: #f8f8f8;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
body {
  margin: 0px;
  padding: 0px;
  font-family: Arial, Helvetica, sans-serif;
}
.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.editingArea {
  padding: 40px;
  height: 100vh;
  overflow-y: hidden;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.copyBubble {
  padding: 2px 5px;
  padding-right: 10px;
  border: 1px solid grey;
  border-radius: 30px;
  display: flex;
  align-items: center;
  max-width: 200px;
  cursor: pointer;
  white-space: nowrap;
  overflow-x: hidden;
}
.copyBubble:hover {
  max-width: 1000px;
  background-color: rgb(238, 238, 238);
  transition: ease-in-out 500ms;
}

.copyBubble > a {
  text-decoration: none;
  font-size: 14px;
  color: rgb(70, 68, 68);
}
a:visited {
  color: rgb(70, 68, 68);
}
.copyBubble img {
  margin-right: 5px;
}
.topnav {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(216, 215, 215);
  padding: 0px 20px;
  justify-content: space-between;
}
.leftContNav{
  display: flex;
  align-items: center;
}
.leftContNav > img {
  margin: 0px 10px;
}
.RightContNav{
  display: flex;
}
.RightContNav p{
  color: gray;
  cursor: pointer;
  padding:15px 10px;
  border-radius: 5px;
  transition: ease-in-out 500ms;
}


.RightContNav p:hover{
  background-color: rgb(216, 216, 216);
  color: rgb(92, 92, 92);
}